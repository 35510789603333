import * as React from 'react';
import { Backdrop } from '@repo/mui/Backdrop';
import { CircularProgress } from '@repo/mui/CircularProgress';

export function LoadingBackdrop({
  open,
  withinContainer,
  color = 'black',
}: {
  open: boolean;
  withinContainer?: boolean;
  color?: 'white' | 'black';
}) {
  const lightTheme = color === 'white';
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: withinContainer ? 'absolute' : 'fixed',
        backgroundColor: lightTheme ? '#fff7' : undefined,
      }}
      open={open}>
      <CircularProgress style={{ color: lightTheme ? undefined : 'inherit' }} />
    </Backdrop>
  );
}
